import React, { useRef } from "react";
import Layout from "../components/Layout";
import Modal from "../components/Modal";
// import Gift from "../components/Gift";
// import { Link } from "gatsby";
// import Repubblica from "../components/Repubblica";
// import Frutta from "../components/Frutta";
// import Verdura from "../components/Verdura";
// import Mix from "../components/Mix";
import Form from "../components/Form";
// import Rating from "../components/Rating";
// import Infographic from "../components/Infographic";
// import Trustpilot from "../components/Trustpilot";
// import RecensioneIsabella from "../components/RecensioneIsabella";
// import Accordion from "../components/Accordion";
import "../css/scroller.css";
// import RecensioneSara from "../components/RecensioneSara";
// import RecensioneSimona from "../components/RecensioneSimona";
// import Arance from "../components/Arance";
// import Limoni from "../components/Limoni";
// import ReactPlayer from "react-player/file";

export default () => {
    const modalRef1 = useRef();

    return (
        <Layout>
            <main className="flex-grow flex flex-col justify-evenly m-auto py-2 px-4 md:px-32">
                <section className="flex flex-wrap flex-col-reverse md:flex-row items-center mt-6">
                    <div className="flex flex-col justify-between mx-auto md:ml-0 md:mr-auto max-w-xl">
                        <h1 className="px-4 pt-5 text-4xl md:text-5xl md:text-justify text-center text-black font-serif tracking-wide">
                            An agricultural dream
                        </h1>
                        <p className="px-4 mt-2 text-xl text-justify text-gray-800 font-sans">
                            Dimora delle balze is located on{" "}
                            <span className="font-black">27 hectares</span> of
                            certified{" "}
                            <span className="font-black">organic fields</span>{" "}
                            which in ancient times took the name of{" "}
                            <span className="font-black">"Passu Latru"</span> or{" "}
                            <span className="font-black">
                                "The Brigand's Pass"
                            </span>
                            , due to the many brigands who used to cross them.
                            <br /><br />
                            It is from this land that the project Passo Ladro
                            was born: an organic farm that harvests and
                            processes{" "}
                            <span className="font-black">
                                seasonal fresh organic produce
                            </span>{" "}
                            using{" "}
                            <span className="font-black">
                                sustainable{" "}
                            </span> and{" "}
                            <span className="font-black">ethical</span>{" "}
                            techniques.
                        </p>
                        <div className="px-4 mt-8 md:text-justify text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <button
                                onClick={() => modalRef1.current.openModal()}
                                className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent"
                            >
                                {/* <a href="https://passoladro.it/"> */}
                                <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                    get early access
                                </h2>
                                {/* </a> */}
                            </button>
                            {/* </div> */}
                        </div>

                        {/* <p className="px-4 mt-8 text-xl text-gray-800 font-sans">
                            Parlano di noi:
                        </p>
                        <div className="px-4 mt-2 text-xl text-gray-800 font-sans text-center md:text-justify">
                            <a
                                href="https://www.ilgusto.it/2021/04/22/news/sicilia_agricoltura_azienda_passo_ladro_pino_lops-297381732/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Repubblica />
                            </a>
                        </div> */}

                        <Modal ref={modalRef1}>
                            <Form />
                        </Modal>
                    </div>

                    <div
                        className="mx-auto md:mr-0 md:ml-auto w-full max-w-5xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <video
                            controls
                            autoPlay
                            muted
                            playsInline
                            loop
                            height="720"
                            width="1280"
                        >
                            {/* <source
                                src="./videos/video_cassette.webm"
                                type="video/webm"
                            /> */}
                            <source
                                src="./videos/video_campi.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </section>
                <section className="flex flex-wrap flex-col md:flex-row items-center mt-20">
                    <div
                        className="mx-auto md:ml-0 md:mr-auto w-full max-w-5xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <video
                            controls
                            muted
                            // autoPlay
                            playsInline
                            loop
                            height="720"
                            width="1280"
                        >
                            {/* <source
                                src="./videos/video_cassette.webm"
                                type="video/webm"
                            /> */}
                            <source
                                src="./videos/clip_pomodoro_final.mp4"
                                type="video/mp4"
                            />
                            {/* https://vimeo.com/500950123 */}
                        </video>
                    </div>
                    <div className="flex flex-col justify-between mx-auto md:mr-0 md:ml-auto max-w-xl">
                        <h1 className="px-4 pt-5 text-4xl md:text-5xl md:text-justify text-center text-black font-serif tracking-wide">
                            The future of tradition
                        </h1>
                        <p className="px-4 mt-2 text-xl text-justify text-gray-800 font-sans">
                            In 2020, a{" "}
                            <span className="font-black">gourmet kitchen</span>{" "}
                            was created at the beginning of the avenue leading
                            to Dimora delle Balze, where our{" "}
                            <span className="font-black">fresh produce</span> is
                            processed by{" "}
                            <span className="font-black">
                                professional chefs
                            </span>{" "}
                            and{" "}
                            <span className="font-black">
                                nutrition specialists
                            </span>
                            . The mix of expertise, sustainability and
                            innovation will allow you to taste certified{" "}
                            <span className="font-black">
                                Sicilian organic delicacies
                            </span>
                            , which delight your palate and support your
                            well-being.
                            <br /><br />
                            If you want such{" "}
                            <span className="font-black">
                                flavours
                            </span> and{" "}
                            <span className="font-black">fragrances</span> to
                            reach you in your city, sign up down here to become
                            one of our special{" "}
                            <span className="font-black">
                                Passo Ladro insiders
                            </span>{" "}
                            and to receive an update on when our{" "}
                            <span className="font-black">
                                online delivery service
                            </span>{" "}
                            will become{" "}
                            <span className="font-black">available</span> to
                            you.
                        </p>

                        <div className="px-4 mt-8 md:text-justify text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <button
                                onClick={() => modalRef1.current.openModal()}
                                className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent"
                            >
                                {/* <a href="https://passoladro.it/"> */}
                                <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                    get early access
                                </h2>
                                {/* </a> */}
                            </button>
                            {/* </div> */}
                        </div>

                        {/* <p className="px-4 mt-8 text-xl text-gray-800 font-sans">
                            Parlano di noi:
                        </p>
                        <div className="px-4 mt-2 text-xl text-gray-800 font-sans text-center md:text-justify">
                            <a
                                href="https://www.ilgusto.it/2021/04/22/news/sicilia_agricoltura_azienda_passo_ladro_pino_lops-297381732/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Repubblica />
                            </a>
                        </div> */}

                        <Modal ref={modalRef1}>
                            <Form />
                        </Modal>
                    </div>
                </section>
            </main>
        </Layout>
    );
};
